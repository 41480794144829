import React from 'react';

const DateBilling = () => {
    return (
        <div>
            
        </div>
    );
}

export default DateBilling;
