export const FirebaseConfig = {
	"projectId": "treasupdate",
	"appId": "1:212923549236:web:a44c084bd18cb373266c61",
	"databaseURL": "https://treasupdate-default-rtdb.firebaseio.com",
	"storageBucket": "treasupdate.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyCreEp8jRr57cqbzEKl-DhGdoTc5H4czAM",
	"authDomain": "treasupdate.firebaseapp.com",
	"messagingSenderId": "212923549236",
	"measurementId": "G-KSL4T771QS"
};